/* ==========================================================================
   Page layout
   ========================================================================== */

body {
	background-color: $bodycolor;
	font-family: $base-font;
	color: $text-color;
	font-size: 17px; //https://github.com/mmistakes/minimal-mistakes/issues/226  o:18.5px, 16.5px 15.5px 15.7px
	line-height: 1.65; //1.5 //1.6 1.65
	//letter-spacing: -0.1px;
}

/*
   Header
   ========================================================================== */

.basicnav {
	//border-bottom: 1px solid #ddd; // #ddd; //1px solid lighten($black,70);  1px solid $grayborder; 
	margin-bottom: 0.8em;
	background-color: #f5f5f5; //Added to separate from page  $graywrap
	//border-bottom: 2px solid $grayborder; //Added   2px
	border-bottom: 2px solid #eee;
	//box-shadow: 0px 2px 2px 0px rgba(102,93,93,0.15);  //rgba(102,93,93,0.15)  0px 2px 2px 0px
	//padding-bottom: 0em;
	padding: 0.9em 0em;  //0.9em
	font-family: $heading-font;   //"PT Sans Narrow", sans-serif;
	//font-size: 22px; //22px
	//font-weight: bold;
	a {
		color: $textcolor;//#777; //$textcolor;
	}
	a:hover {
		color: $linkcolorhover;
	}
}

.navwrapper{
	max-width: 900px; //780px; 875
	margin: 0px auto; 
	padding: 0 15px;
}

.wrapper{
	max-width: 900px; //780px; 875
	margin: 0px auto; 
	padding: 0 15px;
}


.underline {
	color:inherit;
	border-bottom:2px solid #999;
	padding-bottom: 2px
}


/* Main content */
#main {
	margin-top: 1.5em; //2em; 1.5em
	//margin-bottom: 3em;  // 4em;
	//min-height: calc(100vh - 258px );  //http://stackoverflow.com/questions/42294
	.post,
	.page {
		h1 {
			margin-top: 0.8em; //20px;
			margin-bottom: 0.2em; //10px
			line-height: 1.4em;  //1.1em
			//font-size: 2.3em;
			//text-align: center;
			//font-size: 40px;  //Post Title 41px 39px
			a {
				font-size: 40px;  //Post Title 41px 39px
			}
		}
		h2, h3, h4, h5 {
			margin-top: 1.5em; //30px;
			margin-bottom: 0.7em; //15px;
			//text-align: center;
		}
	}
	// .post,
	// .page {
	// 	margin-bottom: 4em; //2em
	// }
}

#index {
	margin-bottom: 2em;

	h3 {
		margin: 0;
		padding-bottom: .5em;
		@include font-rem(28);
		border-bottom: 1px solid $grayborder; //lighten($black,70);
	}
	article {
		h2 {
			margin-top:1.4rem;
			margin-bottom: 4px;
			@include font-rem(20);
			&.link-post {
				margin-bottom: 0px + $doc-line-height;
				margin-bottom: 0rem + ($doc-line-height / $doc-font-size);
			}
		}
		p {
			//margin-top: 0;
			//margin-bottom: 1.86rem;
			//@include font-rem(16); //14
		}
		p+p {
			text-indent: 0;
		}
	}
}

/* SVG Centering */

.outer {
    width: 300px; 
    margin: 0 auto; 
    overflow: visible;
}
.inner {
    position:relative;
    float: right;
    right: 50%;
}
.inner svg {
    position: relative; 
    right:-50%;
}



/* Tables */

table {
    overflow-x: auto;
    display: block;
    font-size: 12px;
}

th, td {
    padding: 8px;  //10px
    text-align: left;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

th {background-color: #f2f2f2;}

//tr:nth-child(even) {background-color: #f2f2f2;}


/* Large feature header image */
.image-wrap {
	position: relative;
	margin-bottom: 2em;
	@include clearfix;
	&:after {
		content: " ";
		display: block;
		position: absolute;
		bottom: 0;
		left: 8%;
		width: 0;
		height: 0;
		border: 10px solid transparent;
		border-bottom-color: $bodycolor;
		@media #{$small} {
			left: 25%;
		}
		@media #{$large} {
			border-width: 20px;
		}
		@media #{$x-large} {
			left: 33.333333333%;
		}
	}
	img {
		width: 100%;
		height: auto;
		-ms-interpolation-mode: bicubic;
	}
	.headline-wrap {
		position: absolute;
		bottom: 25px;
		@include prefix(12,1);
		@media #{$small} {
			left: 25%;
			margin-left: 0;
		}
		@media #{$x-large} {
			@include prefix(12,1);
		}
		h1, h2 {
			color: $white;
			@include text-shadow(0 1px 2px rgba(0,0,0,.25));
		}
	}
}


//Added
// .headline-wrap {
// 	//text-align: center;
// 	font-size: 2.3em;
// 	line-height: 1.1em;
// }


/* Post byline */
.byline {
	clear: both;
	font-size: 80%;  //0.9rem;
	margin-bottom: 1rem;
	//font-weight: 400;
}
.article-author-side,
.article-author-bottom {
	h3 {
		margin-top: 0px; //10px;
		margin-bottom: 0px;
	}
	h4 {
		margin-bottom: 10px;
		margin-top: 0;
	}
	p {
		font-size: 80%;
		font-style: italic;
	}
	a, a:hover {
		border-bottom: 0 solid transparent;
	}
}

/* Default social media links in author sidebar */
.author-social {
  display: block;
  //margin-bottom: 5px;
  line-height: 1.4; //added
  @include font-rem(14);
  color: $black;
  &:visited {
    color: $black;
  }
  &:hover {
    @include scale(1.1);
  }
  &:active {
    @include translate(0, 2px);
  }
  .fa {
		margin-right: 5px;
	}
}


//Added, removed sidebar
.article-author-about {
	//margin-top: 7px; //added to adjust height
	// width: 50%;
	// margin: 0 auto;
	//display: table;
	//margin: 0 auto;
	
	//width: 100%;
    margin:auto;
    overflow:hidden;
    display:block; //inline; //inline-block;
    float:right;
    text-align:center;
    
    padding: 0px 0px 0px 20px;
    
	//margin-left: auto;
	//margin-right: auto;
	//display: none;
	.bio-photo {
		//float: right;
		max-width: 150px;
		margin-bottom: 5px; //added 10 to make up for no h3 uppper margin
		@include rounded(150px); //150px original  20px
		border: 2px solid #aaa;
		//@include rounded(20px);
	}
	.icon-link {
	  display: inline-block;
	  color: #222;  //$black
	  font-size: 16px;
	}
	.icon {
	  height: 15px;
	  width: 15px;
	  display: inline-block;
	  vertical-align: middle;
	  padding: 0px 0px 6px 15px;
	  fill: #222;
	  //margin: 2px 0px;
	  //fill: $textcolor;
	}
	.icon-link:hover {
	  //color: steelblue;
	  //fill: steelblue;
	  transform: scale(1.1);
	}
	a:hover {
	  //color: steelblue;
	  //fill: steelblue;
	  //transform: scale(1.1);
	  //font-size: larger;
	  //line-height: 1em;
	  text-decoration:underline;
	}
}

/* Author module - mobile only */
.article-author-bottom {
	margin-bottom: 1em;
	@media #{$small} {
		display: none;
	}
	.bio-photo {
		float: left;
		margin-right: 25px;
		max-width: 100px;
		@include rounded(150px);
	}
	.author-social {
		display: inline-block;
		margin-right: 10px;
	}
	@media #{$large} {
		h3,
		.bio-photo,
		p,
		.author-social {
			max-width: 150px;
		}
	}
}

/* Post content wrapper */
.article-wrap {
	// Dotted line underlines for links
	p > a,
	p > em > a,
	p > strong > a,
	li > a {
		text-decoration: underline;
	}
}

/* Table of contents */
.toc {
	//display: none; //how to default no display?
	font-size: 95%;
	@media #{$large} {
		display: block;
		@include grid(12,2);
		@include prefix(12,0.5);
		@include suffix(12,0.5);
		position: absolute;
		top: 5.5em;
		right: 0;
		background-color: $white;
	}
	header {
		background: lighten($black, 10);
	}
	h3 {
		margin: 0;
		padding: 5px 10px;
		color: $white;
		@include font-rem(16);
		text-transform: uppercase;
		&:hover {
			cursor: pointer;
		}
	}
	ul {
		margin: 2px 0 0;
		padding: 0;
		line-height: 1;
	}
	li {
		display: block;
		margin: 0 0 1px 0;
		padding: 0;
		font-family: $heading-font;
		list-style-type: none;
		&:last-child {
			border-bottom-width: 0;
		}
		a {
			padding: 10px;
			display: block;
			color: $white;
			text-decoration: none;
			background: lighten($black, 30);
			@include opacity(0.7);
			@include transition(opacity 0.2s ease-in-out);
			&:hover {
				@include opacity(1);
			}
		}
		ul {
			margin: 1px 0 0;
			li a {
				padding-left: 20px;
			}
		}
	}
}

/* Visualization Grid */
/* https://www.taniarascia.com/how-to-build-a-responsive-image-gallery-with-flexbox/*/
.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1rem;
}

/*https://stackoverflow.com/questions/19414856/how-can-i-make-all-images-of-different-height-and-width-the-same-via-css/19414955#19414955*/
.responsive-image {
  max-width: 100%;
  border-radius: 5px;
  //min-width: 100%;
  //min-height: 80%;
  width:  300px;
  height: 175px;
  object-fit: cover;
}

.responsive-image:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.cell {
  margin: 1rem;
  //border: 1px solid #999;
  text-align: center;
  padding: 10px 0px;
}

.cell img {
  display: block;
  margin: auto;
}

.cell-text {
  //margin-top: auto;
  margin-top: 0.5rem;
}

@media screen and (min-width: 600px) {
  .grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
/*  .cell {
    width: calc(50% - 2rem);
  }*/
  .cell {
    width: calc(33.3333% - 2.1rem);  //-2rem
    text-align: center;
  }
}
/*@media screen and (min-width: 1000px) {
  .cell {
    width: calc(33.3333% - 2.1rem);  //-2rem
  }
}*/

/* TOC trigger for collapsing */
#drawer {
	max-height: 100%;
	overflow: hidden;
	&.js-hidden {
		max-height: 0;
	}
}

/* Image grid - not used */
.image-grid {
	@include clearfix;
	list-style: none;
	margin: 0 0 1em;
	padding: 0;
	li {
		@include grid(12,6);
		@media #{$micro} {
			width: 33.333333%;
		}
		@media #{$small} {
			width: 25%;
		}
		@media #{$medium} {
			width: 20%;
		}
		@media #{$large} {
			width: 16.666666666%;
		}
	}
}

/* Recent grid - not used */
.recent-grid {
	@include clearfix;
	list-style: none;
	margin: 1em 0;
	li {
		display: inline;
		a {
			border-bottom: 0 solid transparent;
			&:hover {
				border-bottom: 0 solid transparent;
			}
		}
	}
	img {
		width: 19%;
		margin-bottom: 1%;
	}
}

/* Social sharing links */
/* Social media brand buttons */
.social-share {
  margin-bottom: 0px + $doc-line-height;
  margin-bottom: 0rem + ($doc-line-height / $doc-font-size);
  ul, li {
  	margin: 0;
  	padding: 0;
  	list-style: none;
  }
  li {
  	display: inline-block;
  }
  $social:
  (facebook, $facebook-color),
  (flickr, $flickr-color),
  (foursquare, $foursquare-color),
  (google-plus, $google-plus-color),
  (instagram, $instagram-color),
  (linkedin, $linkedin-color),
  (pinterest, $pinterest-color),
  (rss, $rss-color),
  (tumblr, $tumblr-color),
  (twitter, $twitter-color),
  (vimeo, $vimeo-color),
  (youtube, $youtube-color);
  @each $socialnetwork, $color in $social {
    .#{$socialnetwork} {
      background: $color;
    }
  }
  a {
	  display: block;
	  padding: 8px 20px;
	  text-decoration: none !important;
	  text-transform: uppercase;
	  @include font-rem(14);
	  font-family: $heading-font;
	  font-weight: 700;
	  color: $white;
	  opacity: 0.8;
	  &:hover {
	    opacity: 1;
	  }
	}
	span {
		display: none;
		@media #{$medium} {
			display: inline;
			padding-left: 5px;
		}
	}
	h4 {
		@include font-rem(14);
		margin-bottom: 10px;
		text-transform: uppercase;
	}
}


/* Footer wrapper */
.footer-wrap {
	//background-color: $graywrap; //Added to separate from page
	//border-top: 1px solid $grayborder; //Added
	//padding-top: 2em; //2em
	padding-bottom: 2em;  //3em //2.5 2
	a,
	a:active,
	a:visited,
	p,
	h4,
	h5,
	h6,
	span {
		@include font-rem(14);
	}
	footer {
		max-width: 900px;  //875
		margin: 0px auto; 
		padding: 0 15px;
	}
}

/* Related articles list */
.related-articles {
	@include grid(12,10);
	@include prefix(12,1);
	@include suffix(12,1);
	margin-bottom: 2em;
	@media #{$small} {
		@include grid(12,6);
		@include prefix(12,3);
		@include suffix(12,3);
	}
	@media #{$x-large} {
		@include grid(12,5.75); //12,4.5
		@include prefix(12,4); //12,4
		@include suffix(12,2.25); //12,3.5
	}
	h4 {
		text-transform: uppercase;
		margin-bottom: 0;
	}
	li {
		margin-bottom: 0;
	}
}


/*
   Google search form
   ========================================================================== */

#goog-fixurl {
	ul {
		list-style: none;
		margin-left: 0;
		padding-left: 0;
		li {
			list-style-type: none;
		}
	}
}
#goog-wm-qt {
	width: auto;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 8px 20px;
	display: inline-block;
	@include font-rem(14);
	background-color: $white;
	color: $black;
	border-width: 2px !important;
	border-style: solid !important;
	border-color: lighten($black,50);
	@include rounded(3px);
}
#goog-wm-sb {
	@extend .btn;
}
