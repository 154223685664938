/* ==========================================================================
   Sass variables
   ========================================================================== */

/*
   Typography
   ========================================================================== */

$doc-font-size: 14.0; //16; //13 good 13.5 best 13.75
$doc-line-height: 26; //26;
$paragraph-indent: false; //true !default;
//http://www.ampsoft.net/webdesign-l/WindowsMacFonts.html
//"Palatino Linotype","Book Antiqua", Palatino, serif; Georgia, serif; 
//"Trebuchet MS", sans-serif; 'PT Serif', serif; current: "PT Sans", sans-serif; 
$base-font:  Helvetica, Arial, sans-serif; // "Trebuchet MS", sans-serif;  Georgia, serif; Helvetica, Arial, sans-serif;
$heading-font: Helvetica, Arial, sans-serif; //current: "Trebuchet MS", sans-serif; 'PT Sans Narrow', sans-serif;  Georgia, serif
$code-font: Monaco, "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace;
$alt-font: $base-font;

/*
   Colors
   ========================================================================== */

$bodycolor      : #fff;
$body-color     : $bodycolor;
$textcolor      : rgba(35,35,35,0.9);  //#333332; rgba(51,51,51,0.85); rgba(35,35,35,0.9); 0.95 0.88
$text-color     : $textcolor;
$border-color   : $text-color;
$basecolor 		 : #343434;
$compcolor 		 : spin($basecolor, 180);
$bordercolor    : $basecolor;
$white          : #fff;
$black          : #111;
$accentcolor    : $black;
$graywrap       : #fafafa;  //#f7f7f7;  fafafa
$grayborder     : #ebebeb;  //#d9d9d9;
$border         : lighten($black,70);

/* buttons */
$primary			: rgba(51,51,51,0.80);//$textcolor;//$black;
$success			: #5cb85c;
$warning			: #dd8338;
$danger				: #C64537;
$info				  : #308cbc;

/* brands */
$facebook-color     : #3b5998;
$flickr-color       : #ff0084;
$foursquare-color   : #0cbadf;
$google-plus-color  : #dd4b39;
$instagram-color    : #4e433c;
$linkedin-color     : #4875b4;
$pinterest-color    : #cb2027;
$rss-color          : #fa9b39;
$tumblr-color       : #2c4762;
$twitter-color      : #55acee;
$vimeo-color        : #1ab7ea;
$youtube-color      : #ff3333;

/* links */
$linkcolor 		   : #326891; //steelblue; //#3b5998;//#343434; //red: #EC2028;
$link-color       : $linkcolor;
$linkcolorhover 	: darken($linkcolor, 5); //10
$linkcolorvisited : lighten($linkcolorhover, 20);
$linkcolorfocus 	: darken($linkcolorvisited, 10);

/*
   Breakpoints
   ========================================================================== */

$micro            : "only screen and (min-width: 30em)";
$small            : "only screen and (min-width: 37.5em)";
$medium           : "only screen and (min-width: 48em)";
$large            : "only screen and (min-width: 62em)";
$x-large          : "only screen and (min-width: 86.375em)";
