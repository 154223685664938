/* ==========================================================================
   Syntax highlighting
   ========================================================================== */

// pre.highlight {
// 	padding: 1em;
// }



// .highlight {
// 	background-color: #efefef;
// 	font-family: $code-font;
// 	@include font-rem(12);
// 	line-height: 1.5;
// 	color: #333332;
// 	margin-bottom: 1.5em;
// 	@include rounded(4px);
// 	pre {
// 		position: relative;
// 		margin: 0;
// 		padding: 1em;
// 	}
// 	.lineno { padding-right: 24px; color: lighten(#333332,50);}
// 	.hll { background-color: #ffffcc }
// 	.c { color: #999988; font-style: italic } /* Comment */
// 	.err { color: #a61717; background-color: #e3d2d2 } /* Error */
// 	.k { color: #000000; font-weight: bold } /* Keyword */
// 	.o { color: #000000; font-weight: bold } /* Operator */
// 	.cm { color: #999988; font-style: italic } /* Comment.Multiline */
// 	.cp { color: #999999; font-weight: bold; font-style: italic } /* Comment.Preproc */
// 	.c1 { color: #999988; font-style: italic } /* Comment.Single */
// 	.cs { color: #999999; font-weight: bold; font-style: italic } /* Comment.Special */
// 	.gd { color: #000000; background-color: #ffdddd } /* Generic.Deleted */
// 	.ge { color: #000000; font-style: italic } /* Generic.Emph */
// 	.gr { color: #aa0000 } /* Generic.Error */
// 	.gh { color: #999999 } /* Generic.Heading */
// 	.gi { color: #000000; background-color: #ddffdd } /* Generic.Inserted */
// 	.go { color: #888888 } /* Generic.Output */
// 	.gp { color: #555555 } /* Generic.Prompt */
// 	.gs { font-weight: bold } /* Generic.Strong */
// 	.gu { color: #aaaaaa } /* Generic.Subheading */
// 	.gt { color: #aa0000 } /* Generic.Traceback */
// 	.kc { color: #000000; font-weight: bold } /* Keyword.Constant */
// 	.kd { color: #000000; font-weight: bold } /* Keyword.Declaration */
// 	.kn { color: #000000; font-weight: bold } /* Keyword.Namespace */
// 	.kp { color: #000000; font-weight: bold } /* Keyword.Pseudo */
// 	.kr { color: #000000; font-weight: bold } /* Keyword.Reserved */
// 	.kt { color: #445588; font-weight: bold } /* Keyword.Type */
// 	.m { color: #009999 } /* Literal.Number */
// 	.s { color: #d01040 } /* Literal.String */
// 	.na { color: #008080 } /* Name.Attribute */
// 	.nb { color: #0086B3 } /* Name.Builtin */
// 	.nc { color: #445588; font-weight: bold } /* Name.Class */
// 	.no { color: #008080 } /* Name.Constant */
// 	.nd { color: #3c5d5d; font-weight: bold } /* Name.Decorator */
// 	.ni { color: #800080 } /* Name.Entity */
// 	.ne { color: #990000; font-weight: bold } /* Name.Exception */
// 	.nf { color: #990000; font-weight: bold } /* Name.Function */
// 	.nl { color: #990000; font-weight: bold } /* Name.Label */
// 	.nn { color: #555555 } /* Name.Namespace */
// 	.nt { color: #000080 } /* Name.Tag */
// 	.nv { color: #008080 } /* Name.Variable */
// 	.ow { color: #000000; font-weight: bold } /* Operator.Word */
// 	.w { color: #bbbbbb } /* Text.Whitespace */
// 	.mf { color: #009999 } /* Literal.Number.Float */
// 	.mh { color: #009999 } /* Literal.Number.Hex */
// 	.mi { color: #009999 } /* Literal.Number.Integer */
// 	.mo { color: #009999 } /* Literal.Number.Oct */
// 	.sb { color: #d01040 } /* Literal.String.Backtick */
// 	.sc { color: #d01040 } /* Literal.String.Char */
// 	.sd { color: #d01040 } /* Literal.String.Doc */
// 	.s2 { color: #d01040 } /* Literal.String.Double */
// 	.se { color: #d01040 } /* Literal.String.Escape */
// 	.sh { color: #d01040 } /* Literal.String.Heredoc */
// 	.si { color: #d01040 } /* Literal.String.Interpol */
// 	.sx { color: #d01040 } /* Literal.String.Other */
// 	.sr { color: #009926 } /* Literal.String.Regex */
// 	.s1 { color: #d01040 } /* Literal.String.Single */
// 	.ss { color: #990073 } /* Literal.String.Symbol */
// 	.bp { color: #999999 } /* Name.Builtin.Pseudo */
// 	.vc { color: #008080 } /* Name.Variable.Class */
// 	.vg { color: #008080 } /* Name.Variable.Global */
// 	.vi { color: #008080 } /* Name.Variable.Instance */
// 	.il { color: #009999 } /* Literal.Number.Integer.Long */
// }


// From above:

.highlight {
	background-color: #efefef;
	//font-family: $code-font;
	@include font-rem(12);
	line-height: 1.5;
	color: #333332;
	margin-bottom: 1.5em;
	@include rounded(4px);
	pre {
		position: relative;
		margin: 0;
		padding: 1em;
	}
}


//From jekyll-now:

// .highlight {
//   background-color: #efefef;
//   padding: 7px 7px 7px 10px;
//   //border: 1px solid #ddd;
//   //-moz-box-shadow: 3px 3px rgba(0,0,0,0.1);
//   //-webkit-box-shadow: 3px 3px rgba(0,0,0,0.1);
//   //box-shadow: 3px 3px rgba(0,0,0,0.1);
//   margin: 20px 0 20px 0;
//   overflow: scroll;
// }

code {
  font-family:'Bitstream Vera Sans Mono','Courier', monospace;
}

.highlight .c { color: #586E75 } /* Comment */
.highlight .err { color: #93A1A1 } /* Error */
.highlight .g { color: #93A1A1 } /* Generic */
.highlight .k { color: #859900 } /* Keyword */
.highlight .l { color: #93A1A1 } /* Literal */
.highlight .n { color: rgba(51,51,51,0.80) } /* Name #93A1A1, wanted darker */
.highlight .o { color: #859900 } /* Operator */
.highlight .x { color: #CB4B16 } /* Other */
.highlight .p { color: #93A1A1 } /* Punctuation */
.highlight .cm { color: #586E75 } /* Comment.Multiline */
.highlight .cp { color: #859900 } /* Comment.Preproc */
.highlight .c1 { color: #586E75 } /* Comment.Single */
.highlight .cs { color: #859900 } /* Comment.Special */
.highlight .gd { color: #2AA198 } /* Generic.Deleted */
.highlight .ge { color: #93A1A1; font-style: italic } /* Generic.Emph */
.highlight .gr { color: #DC322F } /* Generic.Error */
.highlight .gh { color: #CB4B16 } /* Generic.Heading */
.highlight .gi { color: #859900 } /* Generic.Inserted */
.highlight .go { color: #93A1A1 } /* Generic.Output */
.highlight .gp { color: #93A1A1 } /* Generic.Prompt */
.highlight .gs { color: #93A1A1; font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #CB4B16 } /* Generic.Subheading */
.highlight .gt { color: #93A1A1 } /* Generic.Traceback */
.highlight .kc { color: #CB4B16 } /* Keyword.Constant */
.highlight .kd { color: #268BD2 } /* Keyword.Declaration */
.highlight .kn { color: #859900 } /* Keyword.Namespace */
.highlight .kp { color: #859900 } /* Keyword.Pseudo */
.highlight .kr { color: #268BD2 } /* Keyword.Reserved */
.highlight .kt { color: #DC322F } /* Keyword.Type */
.highlight .ld { color: #93A1A1 } /* Literal.Date */
.highlight .m { color: #2AA198 } /* Literal.Number */
.highlight .s { color: #2AA198 } /* Literal.String */
.highlight .na { color: #93A1A1 } /* Name.Attribute */
.highlight .nb { color: #B58900 } /* Name.Builtin */
.highlight .nc { color: #268BD2 } /* Name.Class */
.highlight .no { color: #CB4B16 } /* Name.Constant */
.highlight .nd { color: #268BD2 } /* Name.Decorator */
.highlight .ni { color: #CB4B16 } /* Name.Entity */
.highlight .ne { color: #CB4B16 } /* Name.Exception */
.highlight .nf { color: #268BD2 } /* Name.Function */
.highlight .nl { color: #93A1A1 } /* Name.Label */
.highlight .nn { color: #93A1A1 } /* Name.Namespace */
.highlight .nx { color: #555 } /* Name.Other */
.highlight .py { color: #93A1A1 } /* Name.Property */
.highlight .nt { color: #268BD2 } /* Name.Tag */
.highlight .nv { color: #268BD2 } /* Name.Variable */
.highlight .ow { color: #859900 } /* Operator.Word */
.highlight .w { color: #93A1A1 } /* Text.Whitespace */
.highlight .mf { color: #2AA198 } /* Literal.Number.Float */
.highlight .mh { color: #2AA198 } /* Literal.Number.Hex */
.highlight .mi { color: #2AA198 } /* Literal.Number.Integer */
.highlight .mo { color: #2AA198 } /* Literal.Number.Oct */
.highlight .sb { color: #586E75 } /* Literal.String.Backtick */
.highlight .sc { color: #2AA198 } /* Literal.String.Char */
.highlight .sd { color: #93A1A1 } /* Literal.String.Doc */
.highlight .s2 { color: #2AA198 } /* Literal.String.Double */
.highlight .se { color: #CB4B16 } /* Literal.String.Escape */
.highlight .sh { color: #93A1A1 } /* Literal.String.Heredoc */
.highlight .si { color: #2AA198 } /* Literal.String.Interpol */
.highlight .sx { color: #2AA198 } /* Literal.String.Other */
.highlight .sr { color: #DC322F } /* Literal.String.Regex */
.highlight .s1 { color: #2AA198 } /* Literal.String.Single */
.highlight .ss { color: #2AA198 } /* Literal.String.Symbol */
.highlight .bp { color: #268BD2 } /* Name.Builtin.Pseudo */
.highlight .vc { color: #268BD2 } /* Name.Variable.Class */
.highlight .vg { color: #268BD2 } /* Name.Variable.Global */
.highlight .vi { color: #268BD2 } /* Name.Variable.Instance */
.highlight .il { color: #2AA198 } /* Literal.Number.Integer.Long */
